import React, { Fragment, useState, useContext, useEffect } from "react";
import { TezosContext } from "../context/TezosContext";
import ExternalLink from "./icons/external-link";

import axios from "axios";
import { Transition } from "@headlessui/react";
import { TezosOperationType } from "@airgap/beacon-sdk";

import CONTRACTS from "../contracts";

export const getCurrentLevel = async () => {
  const headResponse = await axios.get("https://api.mainnet.tzkt.io/v1/head");

  const head = await headResponse.data;
  const currentLevel = head.level;
  return currentLevel;
};

export const getPlentyReward = async (
  userAddress,
  stakingContractAddress,
  currentLevel,
  Tezos
) => {
  const decimals = 18;
  try {
    const contract = await Tezos.wallet.at(stakingContractAddress);
    const storage = await contract.storage();
    if (storage.totalSupply.toNumber() == 0) {
      throw "No One Staked";
    }
    // Calculate Reward Per Token
    let rewardPerToken = Math.min(
      currentLevel,
      storage.periodFinish.toNumber()
    );
    rewardPerToken = rewardPerToken - storage.lastUpdateTime.toNumber();
    rewardPerToken *= storage.rewardRate.toNumber() * Math.pow(10, decimals);
    rewardPerToken =
      rewardPerToken / storage.totalSupply.toNumber() +
      storage.rewardPerTokenStored.toNumber();
    // Fetch User's Big Map Detais;   ​
    const userDetails = await storage.balances.get(userAddress);
    // Calculating Rewards   ​
    let totalRewards =
      userDetails.balance.toNumber() *
      (rewardPerToken - userDetails.userRewardPerTokenPaid.toNumber());
    totalRewards =
      totalRewards / Math.pow(10, decimals) + userDetails.rewards.toNumber();
    totalRewards = totalRewards / Math.pow(10, decimals); // Reducing to Token Decimals

    return { status: true, totalRewards };
  } catch (error) {
    return { status: false, error };
  }
};

async function fetchFarmRewards(address, farms, currentLevel, tezos) {
  const farmsWithReward = [];
  for (let f of farms) {
    farmsWithReward.push({
      ...f,
      reward: await getPlentyReward(address, f.address, currentLevel, tezos),
    });
  }

  return farmsWithReward;
}

function Harvest({ address }) {
  const [opHash, setOpHash] = useState("");
  const { wallet, tezos } = useContext(TezosContext);
  const [farms, setFarms] = useState([]);

  const [harvesting, setHarvesting] = useState("idle");
  const [findingFarms, setFindingFarms] = useState(false);

  function API_URL(bigMapNumber) {
    return `https://staging.api.tzkt.io/v1/bigmaps/${bigMapNumber}/keys?active=true&limit=5000`;
  }

  async function findUserFarms() {
    let userFarms = [];
    for (let c of Object.keys(CONTRACTS)) {
      const resp = await axios.get(API_URL(CONTRACTS[c]["balanceBigMap"]));
      const balanceMap = await resp.data;
      const userBalance = balanceMap.find((b) => b.key === address);
      if (userBalance === undefined) continue;
      if (parseFloat(userBalance.value.balance) > 0) {
        userFarms.push(CONTRACTS[c]);
      }
    }

    return userFarms;
  }

  async function harvestAll() {
    console.log("Harvest all the user farms");
    setHarvesting("harvesting");
    const ops = farms.map((farm, _) => {
      return {
        kind: TezosOperationType.TRANSACTION,
        amount: "0",
        destination: farm.address,
        parameters: {
          entrypoint: "GetReward",
          value: {
            prim: "Unit",
          },
        },
      };
    });

    console.log("ops", ops);
    try {
      const result = await wallet.client.requestOperation({
        operationDetails: ops,
      });
      setOpHash(result.transactionHash);
      setHarvesting("complete");
    } catch (error) {
      setHarvesting("error");
    }
  }

  useEffect(() => {
    // getCurrentLevel().then((level) => setCurrentLevel(level));
    setFindingFarms(true);
    getCurrentLevel().then((level) =>
      findUserFarms().then((userFarms) => {
        fetchFarmRewards(address, userFarms, level, tezos).then((farms) => {
          setFarms(farms);
          setFindingFarms(false);
        });
      })
    );
  }, []);

  return (
    <div className="mt-12">
      {findingFarms && (
        <div className="text-indigo-500 text-xl font-medium">
          Fetching your farms and rewards...
        </div>
      )}
      <Transition
        as={Fragment}
        show={farms.length > 0 && !findingFarms}
        enter="transition-all ease-out duration-200 transform"
        enterFrom="opacity-0 translate-y-20"
        enterTo="opacity-100 translate-y-0"
      >
        <div>
          <ul className="divide-y divide-gray-200 block border rounded">
            {farms.map((f) => (
              <li
                key={f.address}
                className="text-gray-800 py-4 px-8 w-full flex items-center justify-between"
              >
                <span>{f.name}</span>
                {!f.name.includes("Pond") && (
                  <span className="text-indigo-700">
                    {f.reward.status ? f.reward.totalRewards.toFixed(2) : "-"}{" "}
                    $PLENTY
                  </span>
                )}
              </li>
            ))}
          </ul>
          <div className="mt-8">
            <button
              type="button"
              className="flex w-full items-center justify-center px-6 py-3 border border-transparent rounded-md shadow-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              disabled={farms.length < 1}
              onClick={harvestAll}
            >
              Harvest all the farms
            </button>
          </div>

          <div className="mt-6 text-indigo-500 text-xl font-medium">
            {harvesting === "harvesting" &&
              "Harvesting yours farms right now..."}
            {harvesting === "complete" && "Your farms have been harvested."}
            {harvesting === "error" &&
              "Error harvesting your farms, please try again."}
            {opHash != "" && (
              <a
                href={`https://tzkt.io/${opHash}`}
                target="_blank"
                className="mt-2 flex items-center space-x-1 underline"
              >
                View Transaction <ExternalLink />
              </a>
            )}
          </div>
        </div>
      </Transition>
    </div>
  );
}

export default Harvest;
