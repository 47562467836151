const CONTRACTS = {
  PLENTYQuipuLP: {
    name: "PLENTY/XTZ LP",
    address: "KT1JQAZqShNMakSNXc2cgTzdAWZFemGcU6n1",
    balanceBigMap: 4503,
  },
  // PLENTYKalamLP: {
  //   name: "PLENTY/KALAM LP",
  //   address: "KT1DjDZio7k2GJwCJCXwK82ing3n51AE55DW",
  //   balanceBigMap: 4488,
  // },
  PLENTYtoPLENTY: {
    name: "PLENTY/PLENTY Pool",
    address: "KT1QqjR4Fj9YegB37PQEqXUPHmFbhz6VJtwE",
    balanceBigMap: 4494,
  },
  WRAPtoPLENTY: {
    name: "PLENTY/WRAP Pool",
    address: "KT18oB3x8SLxMJq2o9hKNupbZZ5ZMsgr2aho",
    balanceBigMap: 7988,
  },
  PLENTYtoETHtz: {
    name: "PLENTY/ETHtz Pool",
    address: "KT19asUVzBNidHgTHp8MP31YSphooMb3piWR",
    balanceBigMap: 4491,
  },
  PLENTYtohDAO: {
    name: "PLENTY/hDAO Pool",
    address: "KT1Ga15wxGR5oWK1vBG2GXbjYM6WqPgpfRSP",
    balanceBigMap: 4496,
  },
  PLENTYtoUSDtz: {
    name: "PLENTY/USDtz Pool",
    address: "KT1MBqc3GHpApBXaBZyvY63LF6eoFyTWtySn",
    balanceBigMap: 4490,
  },
  PLENTYtowLINK: {
    name: "PLENTY/wLINK Pool",
    address: "KT1KyxPitU1xNbTriondmAFtPEcFhjSLV1hz",
    balanceBigMap: 4492,
  },
  PLENTYtowMATIC: {
    name: "PLENTY/wMATIC Pool",
    address: "KT1XherecVvrE6X4PV5RTwdEKNzA294ZE9T9",
    balanceBigMap: 4493,
  },
  PLENTYtoKalamPond: {
    name: "PLENTY/KALAM Pond",
    address: "KT1WfLprabHVTnNhWFigmopAduUpxG5HKvNf",
    balanceBigMap: 5137,
  },
  PLENTYtoWRAPPond: {
    name: "PLENTY/WRAP Pond",
    address: "KT1GotpjdBaxt2GiMFcQExLEk9GTfYo4UoTa",
    balanceBigMap: 7985,
  },
};

export default CONTRACTS;
