import React from "react";

function Home({ connectWallet }) {
  return (
    <main className="mt-10 mx-auto max-w-7xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-28">
      <div className="sm:text-center lg:text-left">
        <h1 className="text-4xl tracking-tight font-bold text-gray-900 sm:text-5xl md:text-5xl">
          <span className="block xl:inline">Harvest all your $PLENTY,</span>{" "}
          <span className="block text-indigo-600 xl:inline">in one click.</span>
        </h1>
        <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5  lg:mx-0">
          Harvesting all your $PLENTY farms and pools is irritating, and takes a
          lot of time. Don't worry, we got you. Simplified the process, so that
          you can claim all your PLENTY, at once.
        </p>
        <div className="mt-8 flex justify-center lg:justify-start">
          <div className="rounded-md shadow">
            <button
              href="#"
              className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 md:py-4 md:text-lg md:px-10"
              onClick={connectWallet}
            >
              Connect Wallet
            </button>
          </div>
          <div className="mt-3 sm:mt-0 sm:ml-3">
            <a
              href="https://www.plentydefi.com/"
              className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 md:py-4 md:text-lg md:px-10"
            >
              Visit PLENTY
            </a>
          </div>
        </div>
      </div>
    </main>
  );
}

export default Home;
