import React, { useState, useEffect } from "react";
import axios from "axios";
import { BigNumber } from "bignumber.js";

import CONTRACTS from "../contracts";

function API_URL(address, lastId) {
  // https://api.tzkt.io/v1/accounts/tz1L8hBVpams2fWFC9kS1sPVxfGY6Hmg5TQ8/operations?limit=1000&lastId=43802685
  if (lastId === undefined)
    return `https://api.tzkt.io/v1/accounts/${address}/operations?limit=1000`;

  return `https://api.tzkt.io/v1/accounts/${address}/operations?limit=1000&lastId=${lastId}`;
}

function fetchAllGetRewardOps(operations, contracts) {
  console.log(contracts);
  const filteredOps = operations.filter(
    (op) =>
      contracts.includes(op?.target?.address) &&
      op.parameter.entrypoint === "GetReward"
  );

  return filteredOps;
}

function fetchAllRewardTransactions(getRewardOps, allOps, address) {
  return getRewardOps
    .map((op) =>
      allOps.find(
        (opx) =>
          op.hash === opx.hash &&
          opx.target.alias === "PLENTY" &&
          opx.status === "applied" &&
          op.target.address === opx.sender.address
      )
    )
    .filter(Boolean);
}

function Stats({ address }) {
  const [allPlentyEarned, setAllPlentyEarned] = useState();
  const [loading, setLoading] = useState(true);

  async function fetchAllUserOperations() {
    let allOps = [];
    let resp = await axios.get(API_URL(address));
    let data = await resp.data;

    allOps = allOps.concat(data);

    while (true) {
      resp = await axios.get(API_URL(address, allOps[allOps.length - 1].id));
      data = await resp.data;
      if (data.length === 0) break;
      allOps = allOps.concat(data);
    }
    return allOps;
  }

  useEffect(() => {
    if (address === "") return;
    const CONTRACT_ADDRESSES = Object.entries(CONTRACTS).map(
      (c) => c[1].address
    );
    setLoading(true);
    fetchAllUserOperations().then((ops) => {
      const getRewardOps = fetchAllGetRewardOps(ops, CONTRACT_ADDRESSES);

      const allRewardTransactions = fetchAllRewardTransactions(
        getRewardOps,
        ops,
        address
      );

      let sum = new BigNumber(0);
      for (let tx of allRewardTransactions) {
        sum = sum.plus(new BigNumber(tx.parameter.value.value));
      }
      setAllPlentyEarned(sum);

      setLoading(false);
    });
  }, []);
  return (
    <div className="mt-12">
      {loading ? (
        <p>Loading your stats...</p>
      ) : (
        <div className="shadow-md rounded-lg border border-indigo-200 overflow-hidden sm:p-6 inline-block">
          <p className="text-sm font-medium text-gray-500 truncate">
            Total $PLENTY Earned
          </p>
          <p className="mt-1 text-3xl font-semibold text-gray-900">
            {allPlentyEarned.div(1e18).toFormat(1)} PLENTY
          </p>
        </div>
      )}
    </div>
  );
}

export default Stats;
