import React, { useState, useEffect, useContext, Fragment } from "react";

import { BigNumber } from "bignumber.js";
import { TezosContext } from "./context/TezosContext";

import { TezosOperationType } from "@airgap/beacon-sdk";

import Home from "./components/home";
import Header from "./components/header";
import Footer from "./components/footer";

import DashHeader from "./components/dashHeader";
import Donation from "./components/donation";
import Harvest from "./components/harvest";
import Stats from "./components/stats";

import "./App.css";
const tabs = ["Harvest", "Your stats"];
function App() {
  const STATES = Object.freeze({
    NOT_CONNECTED: 0,
    CONNECTED: 1,
    FINDING_FARMS: 2,
    IDLE: 3,
    HARVESTING: 4,
  });
  const [address, setAddress] = useState("");

  const { wallet, tezos } = useContext(TezosContext);
  const [status, setStatus] = useState(STATES.NOT_CONNECTED);
  const [showDonation, setShowDonation] = useState(true);
  const [currentTab, setCurrentTab] = useState(tabs[0]);

  async function connectWallet() {
    let walletAddress;
    // console.log(wallet);
    // return;
    const activeAccount = await wallet.client.getActiveAccount();
    if (activeAccount) {
      console.log(activeAccount.address);
      walletAddress = activeAccount.address;
    } else {
      const permissions = await wallet.client.requestPermissions();
      walletAddress = permissions.address;
    }

    setAddress(walletAddress);
    setStatus(STATES.CONNECTED);
  }

  async function disconnectWallet() {
    await wallet.client.clearActiveAccount();
    setAddress("");
    setStatus(STATES.NOT_CONNECTED);
  }

  async function donateFn(amount, currency) {
    const addr1 = "tz1L8hBVpams2fWFC9kS1sPVxfGY6Hmg5TQ8";
    const addr2 = "tz2CKXri3qLEmzHepeQ66Ya4qZq2SpyYahuZ";
    const PLENTY = "KT1GRSvLoikDsXujKgZPsGLX8k8VvR2Tq95b";
    let donations;
    if (currency === "XTZ") {
      const amountInMutez = amount * 1e6;
      const half = amountInMutez / 2;
      console.log("amount to donate:", half);
      donations = [
        {
          kind: TezosOperationType.TRANSACTION,
          amount: half,
          destination: addr1,
        },
        {
          kind: TezosOperationType.TRANSACTION,
          amount: half,
          destination: addr2,
        },
      ];
    } else if (currency === "PLENTY") {
      const plentyAmount = new BigNumber(amount).times(1e18);
      const half = plentyAmount.div(2).toString();
      donations = [
        {
          kind: TezosOperationType.TRANSACTION,
          amount: "0",
          destination: PLENTY,
          parameters: {
            entrypoint: "transfer",
            value: {
              prim: "Pair",
              args: [
                {
                  string: address, //This is the address from where the Plenty will be sent FROM
                },
                {
                  prim: "Pair",
                  args: [
                    {
                      string: addr1, //These are the addresses from where the Plenty will be sent TO
                    },
                    {
                      int: half, //This is half the donation, converted to a string which is a requirement for the script to work.
                    },
                  ],
                },
              ],
            },
          },
        },
        {
          kind: TezosOperationType.TRANSACTION,
          amount: "0",
          destination: PLENTY,
          parameters: {
            entrypoint: "transfer",
            value: {
              prim: "Pair",
              args: [
                {
                  string: address, //This is the address from where the Plenty will be sent FROM
                },
                {
                  prim: "Pair",
                  args: [
                    {
                      string: addr2, //These are the addresses from where the Plenty will be sent TO
                    },
                    {
                      int: half, //This is half the donation, converted to a string which is a requirement for the script to work.
                    },
                  ],
                },
              ],
            },
          },
        },
      ];
    }

    try {
      const result = await wallet.client.requestOperation({
        operationDetails: donations,
      });

      console.log("result", result);
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <div className="min-h-screen flex flex-col relative overflow-hidden">
      <Header
        userConnected={address.length > 0}
        disconnectWallet={disconnectWallet}
        connectWallet={connectWallet}
      />
      <div className="flex-1">
        {status === STATES.NOT_CONNECTED ? (
          <Home connectWallet={connectWallet} />
        ) : (
          <div className="p-12 max-w-2xl mb-12 mx-auto">
            <DashHeader
              address={address}
              tabs={tabs}
              currentTab={currentTab}
              setCurrentTab={setCurrentTab}
            />
            {currentTab === tabs[0] && <Harvest address={address} />}
            {currentTab === tabs[1] && <Stats address={address} />}

            {showDonation && (
              <Donation donateFunction={donateFn} setShow={setShowDonation} />
            )}
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
}

export default App;
