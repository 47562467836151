import React from "react";
import Button from "./button";

function Header({ userConnected, disconnectWallet, connectWallet }) {
  return (
    <header className="px-12 py-6 flex items-center justify-between border-b-2">
      <h1 className="text-2xl font-medium text-indigo-700">Harvest Plenty</h1>
      <div>
        {userConnected ? (
          <button
            type="button"
            className="inline-flex items-center px-6 py-3 border border-gray-300 shadow-sm text-base font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            onClick={disconnectWallet}
          >
            Disconnect Wallet
          </button>
        ) : (
          <button
            type="button"
            className="inline-flex items-center px-6 py-3 border border-gray-300 shadow-sm text-base font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            onClick={connectWallet}
          >
            Connect Wallet
          </button>
        )}
      </div>
    </header>
  );
}

export default Header;
