import React from "react";

export default function DashHeader({
  address,
  tabs,
  currentTab,
  setCurrentTab,
}) {
  return (
    <div className="pb-5 border-b border-gray-200 sm:pb-0">
      <h3 className="text-2xl leading-6 font-bold text-gray-700">
        {address.slice(0, 5)}...{address.slice(-3)}
      </h3>
      <div className="mt-3 sm:mt-4">
        <div className="hidden sm:block">
          <nav className="-mb-px flex space-x-8">
            {tabs.map((tab) => (
              <button
                key={tab}
                className={`
                            ${
                              currentTab === tab
                                ? "border-indigo-500 text-indigo-600"
                                : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300"
                            } 
                            whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm focus:outline-none
                          `}
                onClick={() => {
                  setCurrentTab(tab);
                }}
              >
                {tab}
              </button>
            ))}
          </nav>
        </div>
      </div>
    </div>
  );
}
