import React, { useState } from "react";

function Donation({ donateFunction, setShow }) {
  const [value, setValue] = useState("10.0");
  const [selected, setSelected] = useState("XTZ");

  function submit(e) {
    e.preventDefault();
    if (value === "") return;

    const amountToDonate = parseFloat(value);
    donateFunction(amountToDonate, selected);
  }
  return (
    <div className="bg-white border rounded shadow-lg fixed bottom-0 right-0 mb-20 mr-8 max-w-xl">
      <div className="px-4 py-5 sm:p-6">
        <div className="flex items-center justify-between">
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            Help us improve the site
          </h3>
          <button
            onClick={() => setShow(false)}
            className="inline-flex items-center p-1 border border-transparent rounded-full shadow-sm  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            <svg
              className="w-4 h-4"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>
        <div className="mt-4 max-w-xl text-sm text-gray-500">
          <p>
            Hey, if you're enjoying using the tool, consider donating{" "}
            <span className="text-blue-600">XTZ</span> or{" "}
            <span className="text-purple-600">PLENTY</span> to help fund the
            development of the platform.
          </p>
        </div>
        <form className="mt-6 sm:flex sm:items-center" onSubmit={submit}>
          <div className="w-full sm:max-w-xs">
            <div className="relative rounded-md shadow-sm">
              <input
                type="number"
                className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pr-10 border-gray-300 rounded-md"
                placeholder="5.0"
                value={value}
                onChange={(e) => setValue(e.target.value)}
              />
              <div className="absolute inset-y-0 right-0 flex items-center">
                <label htmlFor="currency" className="sr-only">
                  Currency
                </label>
                <select
                  id="currency"
                  name="currency"
                  className="focus:ring-indigo-500 focus:border-indigo-500 h-full py-0 pl-2 pr-7 border-transparent bg-transparent text-gray-500 sm:text-sm rounded-md"
                  value={selected}
                  onChange={(e) => setSelected(e.target.value)}
                >
                  <option value="XTZ">XTZ</option>
                  <option value="PLENTY">PLENTY</option>
                </select>
              </div>
            </div>
          </div>

          <button
            type="submit"
            className="ml-2 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Donate
          </button>
        </form>
      </div>
    </div>
  );
}

export default Donation;
