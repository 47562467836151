import React from "react";

function Footer() {
  return (
    <footer className="px-12 py-4 text-gray-800 border-t flex justify-between items-center">
      <div>
        Built by{" "}
        <a
          href="https://twitter.com/runner_data"
          target="_blank"
          className="text-indigo-700"
        >
          DataRunner
        </a>{" "}
        and{" "}
        <a
          href="https://twitter.com/manangouhari"
          target="_blank"
          className="text-indigo-700"
        >
          Manan
        </a>
      </div>
      <div className="text-gray-400">
        Not affiliated with{" "}
        <a href="https://www.plentydefi.com/" target="_blank">
          PlentyDefi.com
        </a>
      </div>
    </footer>
  );
}

export default Footer;
